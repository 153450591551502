<template>
  <v-layout
    align-center
    justify-center
  >
    <v-flex
      xs11
      class="pb-12"
    >
      <ValidationObserver ref="obs" v-slot="{ invalid, validated, passes }">
      <v-form @submit.prevent="passes(enviarEncuesta)">
        <v-row>
          <v-col>
            <v-alert
              type="info"
              color="#193663"
              dark
            >
              Obtener información de retorno de los clientes es un requisito fundamental de la Norma  ISO/IEC 17025:2017, dicha información es utilizada en la mejora continua de nuestros servicios, por tal motivo, le solicitamos que complete la siguiente encuesta, y así conocer mejor sus necesidades y expectativas.
            </v-alert>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h3 class="">Por favor marque su opinión sobre cada respuesta</h3>
          </v-col>
        </v-row>
        <v-simple-table class="mt-2">
          <template v-slot:default>
            <tbody>
              <tr
                v-for="questions in firstSectionQuestions"
                :key="questions.question"
              >
                <td style="width: 30%">{{questions.question}}</td>
                <td>
                  <ValidationProvider :name="questions.question" rules="required" v-slot="{errors, valid}">
                    <v-radio-group
                      v-model="questions.answer"
                      :error-messages="errors"
                      :success="valid"
                      :row="!isMobile"
                      dense
                    >
                      <v-radio
                        v-for="option in radioData"
                        :key="option"
                        :value="option"
                      >
                        <template v-slot:label>
                          <div class="text-body-2">{{ option }}</div>
                        </template>
                      </v-radio>
                    </v-radio-group>
                  </ValidationProvider>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
        <v-row>
          <v-col>
            <h3 class="mt-5">Por favor responder las siguientes preguntas</h3>
          </v-col>
        </v-row>
        <v-row v-for="questions in secondSectionQuestions" :key="questions.question">
          <v-col class="align-self-center">{{questions.question}}</v-col>
          <v-col>
            <ValidationProvider :name="questions.question" rules="required" v-slot="{errors, valid}">
              <v-radio-group
                row
                dense
                v-model="questions.answer"
                :error-messages="errors"
                :success="valid"
              >
                <v-radio :value="true" label="Si"></v-radio>
                <v-radio :value="false" label="No"></v-radio>
              </v-radio-group>
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h4>
              Para cualquier sugerencia o comentario, por favor complete
              el campo Observaciones. Estos comentarios serán muy valorados
              para poder mejorar el servicio.
            </h4>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              label="Observaciones"
              class="mt-4"
              name="name"
              v-model="observations"
            ></v-textarea>
          </v-col>
        </v-row>

        <div align-self-center class="mt-1">
          <v-btn type="submit" block @click.prevent="passes(enviarEncuesta)" color="primary darken-3"
            dark :loading="loading">Enviar</v-btn>
        </div>
      </v-form>
      </ValidationObserver>
    </v-flex>
  </v-layout>
</template>

<script>

import {
  ValidationObserver,
  ValidationProvider
} from "vee-validate";

export default {
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data: () => ({
    loading: false,
    firstSectionQuestions: [
      {question: 'Asistencia Administrativa', answer: null},
      {question: 'Rapidez en la Asistencia Administrativa', answer: null},
      {question: 'Atención de Reclamos', answer: null},
      {question: 'Asesoramiento Técnico', answer: null},
      {question: 'Plazos de entrega de material para toma de muestra', answer: null},
      {question: 'Calidad del material enviado para la toma de muestra', answer: null},
      {question: 'Plazo de entrega de los resultados', answer: null},
      {question: 'Presentación, comprensión y calidad de los Protocolos', answer: null},
      {question: 'Relación precio/servicio', answer: null},
      {question: 'Servicio ofrecido en comparación a otros laboratorios regionales', answer: null},
    ],
    secondSectionQuestions: [
      {question: '¿Utiliza la página web para obtener sus protocolos? ', answer: null},
      {question: '¿Utiliza la aplicación móvil para obtener sus resultados?', answer: null},
      {question: '¿La aplicación móvil le facilito el reenvío de resultados y documentos a sus clientes?', answer: null},
    ],
    radioData: [
      'Muy satisfecho',
      'Satisfecho',
      'Medianamente satisfecho',
      'Insatisfecho',
    ],
    observations: null,
  }),

  mounted () {
    if (sessionStorage.getItem('verEncuesta') !== 'S') {
      this.$router.replace({name: 'protocolos'})
    }
  },

  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mobile
    },
  },

  methods:{
    enviarEncuesta: function () {
      this.loading = true

      let data = {
        clientData: null,
        firstSectionQuestions: JSON.stringify(this.firstSectionQuestions),
        secondSectionQuestions: JSON.stringify(this.secondSectionQuestions),
        observations: this.observations
      }

      this.$soapClient.call('responderEncuesta', data)
        .then(() => {
          sessionStorage.setItem('verEncuesta', 'N')
          this.$eventHub.$emit('snackbar-message', '¡Encuesta enviada!', 'success')
          this.$router.replace({name: 'protocolos'})
        })
        .catch((error) => {
          if ('message' in error) {
            this.$eventHub.$emit('snackbar-message', error.message, 'error')
          } else {
            this.$eventHub.$emit('snackbar-message', 'Disculpe, ocurrío un error al procesar su solicitud', 'error')
          }
        })
        .then(() => {
          this.loading = false
        })
    },
  },
};

</script>

